<template>
  <footer class="
    container mt-40 p-6 pb-48 max-w-xl text-center flex flex-row items-end justify-center
  ">
    <div class="mr-2 flex flex-row">
      Сделано с
      <div class="mx-3 animate-ping" role="img" aria-label="emoji">❤</div>
      командой
    </div>
      <a
        href="https://github.com/talkiiing"
        class="text-yellow-400 flex flex-row items-end hover:text-white"
      >
        reportWebVitals.
        <div class="text-black bg-yellow-300 w-12 h-12 flex items-end px-0.5">js️</div>
      </a>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'VFooter',
};
</script>
