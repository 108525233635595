<template>
  <div class="text-center">
    <FeedbackForm />
  </div>
</template>
<script>
import FeedbackForm from '@/components/FeedbackForm.vue';

export default {
  name: 'Home',
  components: { FeedbackForm },
};
</script>
